<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <icon-class-bar
          title="Noteneingabe"
          :marks="true"
          :comments="true"
          :presence="true"
          :edit_marks="b_EditMarks"
          current="final-marks"
          @edit-mode="editMode=true"
        />
        <v-card-text>
          <v-row
            align="start"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-select
                v-model="s_ExamEventTitleSelected"
                :items="a_ExamEventTitle"
                filled
                label="Prüfungsanlass"
                dense
                outlined
                color="success"
                class="mt-3"
              />
            </v-col>
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-select
                v-model="s_ExamGroupSelected"
                :items="a_ExamGroup"
                filled
                label="Prüfung"
                dense
                outlined
                color="success"
                class="mt-3"
              />
            </v-col>
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="a_Students"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
            dense
            :loading="b_Loading"
          >
            <template v-slot:header.mark="{ header }">
              {{ header.text }}<br>
              Datum: {{ header.date }}
            </template>
            <template v-slot:item.name="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="require('@/assets/images/avatars/001-man.svg')"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.mark="{item}">
              <v-row
                v-if="!editMode && !item.edit"
                class="ma-0 font-weight-medium"
              >
                <div @click="item.edit = true">
                  {{ (item.mark !== null && item.mark !== '') ? item.mark : '-' }}
                </div>
              </v-row>
              <v-row
                v-else
                no-gutters
                class="my-1"
              >
                <v-col md="3">
                  <v-text-field
                    :value="item.mark"
                    hide-details="auto"
                    outlined
                    dense
                    :disabled="!getEditMode(item.i_Status, item.i_Flag)"
                    @change="updateResult(item, $event)"
                    @keydown="validateResult()"
                    :error-messages="getErrorMessages(item.i_AddressRoleID)"
                  />
                </v-col>
                <v-btn 
                  icon 
                  color="success" 
                  v-if="item.edit"
                  @click="setExamResult(item)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn 
                  icon 
                  v-if="item.edit"
                  @click="restoreExamResult(item)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
          <div
            v-if="editMode"
            class="d-flex flex-wrap mt-4"
          >
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="setExamGroupResult(a_Students, 'draft')"
              :disabled="areAllDefinitive"
            >
              {{ $t('save_draft')}}
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="blue"
              small
              @click="setDefinitiveExamGroupResult()"
              :disabled="areAllDefinitive"
            >
              {{ $t('save_definitive') }}
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="restoreResult()"
            >
              {{ $t('general.cancel') }}
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { decimal, between } from 'vuelidate/lib/validators'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Noteneingabe',
    },
    components: {
      IconClassBar,
    },
    mixins: [validationMixin],
    data () {
      return {
        a_ExamEventTitle: [],
        a_ExamGroup: [],
        s_ExamEventTitleSelected: '',
        s_ExamGroupSelected: '',
        editMode: false,
        search: '',
        selected: [],
        headers: [
          {
            text: 'Lernende',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Resultat',
            value: 'mark',
            date: '',
          },
        ],
        a_Students: [],
        a_StudentsNotUpdate: [],
        a_ExamEvent: [],
        f_CurrentResult: null,
        a_ResultError: [],
        a_UpdatedExamResult: [],
        snackbar: false,
        b_EditMarks: false,
        b_Loading: true,
        i_TacherAddressRoleID: null,
      }
    },
    watch: {
      a_Students () {
        if (this.a_Students.length > 0) {
          this.b_EditMarks = true
        }
        else {
          this.b_EditMarks = false
        }
      },
      a_ExamEvent() {
        this.a_ExamEventTitle = this.a_ExamEvent.map((o_ExamEvent) => {
          return o_ExamEvent.s_Name
        });
      },
      s_ExamEventTitleSelected() {
        this.a_Students = []
        this.s_ExamGroupSelected = ''
        if (this.s_ExamEventTitleSelected !== '') {
          const Li_IndexExam = this.a_ExamEvent.findIndex((o_ExamEvent) => {
            return o_ExamEvent.s_Name === this.s_ExamEventTitleSelected
          });
          this.a_ExamGroup = this.a_ExamEvent[Li_IndexExam].a_Exam.map((o_Exam) => {
            return o_Exam.s_Name
          });
        }
      },
      s_ExamGroupSelected () {
        this.a_Students = []
        this.b_Loading = true
        if (this.s_ExamGroupSelected !== '') {
          this.getExamPersons()
        }
      },
      i_PlanningPeriodID () {
        this.a_ExamEvent = []
        this.a_ExamGroup = []
        this.a_Students = []
        this.a_ExamEventTitle = []
        this.s_ExamEventTitleSelected = ''
        this.s_ExamGroupSelected = ''
        this.getExamEvents()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      areAllDefinitive () {
        const La_StudentsDraft = this.a_Students.filter(student => student.i_Status !== 0)
        if (La_StudentsDraft.length > 0) return false
        else return true
      }
    },
    methods: {
      getExamEvents() {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/exam-event`, {})
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.b_Loading = false
            this.a_ExamEvent = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getExamPersons() {
        const Li_TeacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        const Li_ExamGroupID = this.getExamGroupIDFromName(this.s_ExamGroupSelected);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/exam-event/group/${Li_ExamGroupID}`, {
          i_AddressRoleID: Li_TeacherID, 
          i_PlanningPeriod: this.getPlanningPeriodID,
          i_ExamEventID: this.getExamEventIDFromName()
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_Students = response
            this.b_Loading = false
            this.a_StudentsNotUpdate = JSON.parse(JSON.stringify(response))
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getExamGroupIDFromName(As_ExamGroupName) {
        const Li_IndexExam = this.a_ExamEvent.findIndex((o_ExamEvent) => {
          return o_ExamEvent.s_Name === this.s_ExamEventTitleSelected
        });
        const La_ExamGroupSelected = this.a_ExamEvent[Li_IndexExam].a_Exam.filter((o_Exam) =>
          o_Exam.s_Name === As_ExamGroupName
        );
        return La_ExamGroupSelected[0].i_ExamGroupID;
      },
      getExamEventIDFromName() {
        const Li_IndexExam = this.a_ExamEvent.findIndex((o_ExamEvent) => {
          return o_ExamEvent.s_Name === this.s_ExamEventTitleSelected
        });
        return this.a_ExamEvent[Li_IndexExam].i_ExamEventID;
      },
      setExamResult(item) {
        this.validateResult()
        if (this.$v.$invalid) return
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/exam/student`, {
          f_Result: item.mark,
          f_MaxValue: item.f_MaxValue,
          i_ExamID: item.i_ExamID,
          i_StudentAddressRoleID: item.i_AddressRoleID,
          i_ResultTemplateID: item.i_ResultTemplateID,
          i_AddressRoleID: this.i_TacherAddressRoleID
        })
          .then(response => {
            item.edit = false;
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.requestSent = true
            this.responseMessage = 'Pensum gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setDefinitiveExamGroupResult() {
        const La_Students = this.a_Students.filter(student => student.i_Status !== 0)
        La_Students.forEach(student => {
          student.i_Status = 0
        })
        this.setExamGroupResult(La_Students, 'definitive')
      },
      setExamGroupResult(Aa_Students, As_Status) {
        this.validateResult()
        let La_Students = []
        if (this.a_ResultError.length > 0) return
        this.editMode = false
        if (As_Status === 'draft') {
          La_Students = Aa_Students.filter(student => student.i_Status !== 0)
          La_Students.forEach(student => {
            if (student.i_Status !== 0) student.i_Status = 1
          })
        }
        if (As_Status === 'definitive') La_Students = Aa_Students
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/exam/group`, {
          a_Students: La_Students,
          i_AddressRoleID: this.i_TacherAddressRoleID
        })
          .then(response => {
            this.editMode = false;
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_StudentsNotUpdate = JSON.parse(JSON.stringify(this.a_Students))
            this.requestSent = true
            this.responseMessage = 'Resultate gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateResult(Ao_Student, event) {
        if(Ao_Student.mark !== event) {
          this.addUpdatedExamResult({
            i_AddressRoleID: Ao_Student.i_AddressRoleID,
            f_PreviousResult: Ao_Student.mark,
            f_CurrentResult: event
          });
          Ao_Student.mark = event
        }
      },
      validateResult() {
        this.a_Students.forEach(student => {
          const Li_IndexError = this.a_ResultError.findIndex(error => {
            return error.i_AddressRoleID ===  student.i_AddressRoleID
          });
          this.f_CurrentResult = student.mark
          this.$v.f_CurrentResult.$touch()
          if(!this.$v.$invalid && Li_IndexError > -1) this.a_ResultError.splice(Li_IndexError, 1);
          if(this.$v.$invalid && Li_IndexError === -1) this.a_ResultError.push({
            i_AddressRoleID: student.i_AddressRoleID,
            mark: student.mark
          });
        });
      },
      addUpdatedExamResult(Ao_ChangedResult) {
        const Li_IndexResult = this.a_UpdatedExamResult.findIndex(examResult => {
          return examResult.i_AddressRoleID ===  Ao_ChangedResult.i_AddressRoleID
        });
        if(Li_IndexResult === -1) this.a_UpdatedExamResult.push(Ao_ChangedResult);
        else this.a_UpdatedExamResult[Li_IndexResult] = Ao_ChangedResult;
      },
      restoreResult() {
        this.a_Students = JSON.parse(JSON.stringify(this.a_StudentsNotUpdate))
        this.editMode = false
      },
      restoreExamResult(Ao_ExamResult){
        const Li_IndexResult = this.a_UpdatedExamResult.findIndex(examResult => {
          return examResult.i_AddressRoleID ===  Ao_ExamResult.i_AddressRoleID
        });
        Ao_ExamResult.edit = false;
        if(Li_IndexResult === -1) return;
        Ao_ExamResult.mark = this.a_UpdatedExamResult[Li_IndexResult].f_PreviousResult;
        this.a_UpdatedExamResult.splice(Li_IndexResult, 1);
      },
      getErrorMessages(Ai_AddressRoleID) {
        const errors = []
        const Li_IndexError = this.a_ResultError.findIndex(error => {
          return error.i_AddressRoleID ===  Ai_AddressRoleID
        });
        if(Li_IndexError !== -1) errors.push('falscher Wert');
        return errors;
      },
      getEditMode(Ai_Status, Ai_Flag) {
        if (Ai_Flag === -1) return true
        if (Ai_Status === 0 || Ai_Status === null) return false
        return true
      }
    },
    beforeMount() {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      this.getExamEvents()
    },
    validations: {
      f_CurrentResult: {
        decimal,
        between: between(0, 100)
      }
    }
  }
</script>
